'use client'

import React, { useState, useEffect } from 'react'
import { UserAuth } from '../../context/AuthContext'
import { FaCalendarDay, FaExclamationTriangle } from 'react-icons/fa'
import { get, getDatabase, ref } from 'firebase/database'

const DaySection = ({ title, content, isVisible, onToggle }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
    <div
      className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 hover:bg-gray-200"
      onClick={onToggle}
    >
      <div className="flex items-center">
        <FaCalendarDay className="text-gray-600 text-xl mr-2" />
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      </div>
      <span className="text-gray-600">{isVisible ? '▲' : '▼'}</span>
    </div>
    {isVisible && (
      <div className="p-4 bg-white">
        {content.map((exercise, index) => (
          <div key={`${exercise.exercise.exercicio}-${index}`} className="mb-6 border-b pb-4">
            <p className="font-semibold text-lg mb-2">{exercise.exercise.exercicio}</p>
            {exercise.exercise.gif && (
              <img src={exercise.exercise.gif} alt={exercise.exercise.exercicio} className="w-full max-w-xs mx-auto mt-2 mb-2 rounded-lg shadow-md" />
            )}
            <p className="text-sm text-gray-700">{`${exercise.sets} séries x ${exercise.reps} repetições`}</p>
          </div>
        ))}
      </div>
    )}
  </div>
)

export default function LocalTreino() {
  const [currentWorkout, setCurrentWorkout] = useState(null)
  const [expandedDays, setExpandedDays] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const { user } = UserAuth()

  useEffect(() => {
    const fetchWorkout = async () => {
      console.log('Fetching workout...')
      setError(null)
      try {
        if (user?.uid) {
          const db = getDatabase()
          const userRef = ref(db, `users/${user.uid}/currentWorkout`)
          const snapshot = await get(userRef)
          
          console.log('Firebase response:', snapshot.val())
          
          if (snapshot.exists()) {
            const workoutData = snapshot.val()
            console.log('Workout data:', workoutData)
            setCurrentWorkout(workoutData)
            setExpandedDays(Object.fromEntries(Object.keys(workoutData).map(day => [day, true])))
          } else {
            console.log('No workout found')
          }
        }
      } catch (error) {
        console.error('Error fetching workout:', error)
        setError('Ocorreu um erro ao buscar seu treino. Por favor, tente novamente mais tarde.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchWorkout()
  }, [user])


  const toggleDayExpansion = (day) => {
    setExpandedDays(prev => ({
      ...prev,
      [day]: !prev[day]
    }))
  }

  // Função para ordenar os dias da semana de segunda a sexta, ajustando conforme os nomes do banco de dados
  const sortDaysOfWeek = (workoutData) => {
    // Definir a ordem correta dos dias
    const order = ['segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado', 'domingo']
    
    // Garantir que o nome dos dias está no formato esperado
    const formattedWorkoutData = Object.entries(workoutData).map(([key, value]) => {
      let dayName = key.toLowerCase()
      if (dayName === 'monday') dayName = 'segunda'
      if (dayName === 'tuesday') dayName = 'terça'
      if (dayName === 'wednesday') dayName = 'quarta'
      if (dayName === 'thursday') dayName = 'quinta'
      if (dayName === 'friday') dayName = 'sexta'
      if (dayName === 'saturday') dayName = 'sásbado'
      if (dayName === 'sunday') dayName = 'domingo'

      return [dayName, value]
    })

    // Ordenar os dias de acordo com a ordem estabelecida
    return formattedWorkoutData.sort(([dayA], [dayB]) => order.indexOf(dayA) - order.indexOf(dayB))
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    )
  }

  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-50">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Seu Treino Personalizado</h1>
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div className="flex items-center">
            <FaExclamationTriangle className="mr-2" />
            <p>{error}</p>
          </div>
        </div>
      )}
      {currentWorkout ? (
        <div className="space-y-4">
          {sortDaysOfWeek(currentWorkout).map(([day, exercises]) => (
            <DaySection
              key={day}
              title={day.charAt(0).toUpperCase() + day.slice(1)}
              content={exercises}
              isVisible={expandedDays[day]}
              onToggle={() => toggleDayExpansion(day)}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">Nenhum treino encontrado. Gere um novo treino para começar!</p>
      )}
    </div>
  )
}
